













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseSkeletonLine extends Vue {
    @Prop() value!: boolean;
    @Prop() height!: any;
    @Prop() width!: any;
    @Prop() center!: boolean;

    get showSkeleton() {
        return this.value;
    }
    set showSkeleton(newValue: boolean) {
        this.$emit("input", newValue);
    }

    styleGenerator(
        height: any = "24px",
        width: any = "75%",
        color: any = "#d00"
    ) {
        let usedStyle: string = `height: ${height} !important; width: ${width} !important; line-height: 140%; !important`;

        return usedStyle;
    }
}
