





























import BaseSkeletonLine from "../../Base/BaseSkeletonLine.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: { BaseCard, BaseSkeletonLine },
})
export default class SharedCompaniesProfileStatistic extends Vue {
    @Prop() statistics!: any[];
    @Prop() loading!: boolean;
}
